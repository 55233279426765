<template>
  <div>
    <b-row>
      <b-col sm="3">
        <b-card>
          <validation-observer ref="selectForm">
            <b-form>
              <b-row>
                <b-col sm="12">
                  <div class="full-width d-flex justify-content-center mb-2">
                    <div
                      v-if="!userData.avatarUrl || userData.avatarUrl === ''"
                      class="cursor-pointer"
                      @click="$refs.avatar.$refs.input.click()"
                    >
                      <b-avatar
                        size="100px"
                        :text="avatarText(userData.firstname)"
                        :variant="`light-${resolveUserRoleVariant(userData.role)}`"
                      />
                    </div>
                    <b-img
                      v-if="userData.avatarUrl && userData.avatarUrl !== ''"
                      :src="userData.avatarUrl"
                      width="100px"
                      height="100px"
                      class="cursor-pointer rounded-full"
                      @click="$refs.avatar.$refs.input.click()"
                    />
                    <b-form-file
                      ref="avatar"
                      class="hidden"
                      v-model="avatar"
                      @input="handleUploadAvatar"
                    />
                  </div>
                </b-col>
                <b-col sm="12">
                  <b-form-group>
                    <label for="account-status">Status <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="status"
                      rules="required"
                    >
                      <v-select
                        v-model="userData.status"
                        :state="errors.length > 0 ? false:null"
                        :options="statusOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="account-status"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group>
                    <label for="account-role">Role <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="role"
                      rules="required"
                    >
                      <v-select
                        v-model="userData.role"
                        :state="errors.length > 0 ? false:null"
                        :options="roleOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        :disabled="true"
                        input-id="account-role"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-button
                    class="mt-1 full-width"
                    variant="danger"
                    @click="() => makeResetPasswordToast(userData.id, userData.email)"
                  >
                    <feather-icon
                      icon="RotateCcwIcon"
                      size="16"
                      class="mr-50"
                    />
                    <span>Reset Password</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
      <b-col sm="9">
        <b-card>
          <validation-observer ref="inputForm">
            <b-form>
              <h4>Employee Information</h4>
              <b-row>
                <b-col sm="6">
                  <b-form-group>
                    <label for="account-empId">Employment ID</label>
                    <b-form-input
                      v-model="userData.id"
                      id="account-empId"
                      placeholder="Employment ID"
                      name="empId"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6">
                  <b-form-group>
                    <label for="account-firstname">First name <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="first name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="userData.firstname"
                        :state="errors.length > 0 ? false:null"
                        id="account-firstname"
                        placeholder="First name"
                        name="firstname"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group>
                    <label for="account-lastname">Last name <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="last name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="userData.lastname"
                        :state="errors.length > 0 ? false:null"
                        id="account-lastname"
                        placeholder="Last name"
                        name="lastname"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group>
                    <label for="account-company">Company</label>
                    <b-form-input
                      v-model="userData.company"
                      id="account-company"
                      placeholder="e.g. AKARA, VASO Restuarant"
                      name="company"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group>
                    <label for="account-team">Department or team</label>
                    <b-form-input
                      v-model="userData.team"
                      id="account-team"
                      placeholder="e.g. Marketing, Content Creator"
                      name="team"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group>
                    <label for="account-about">About me</label>
                    <b-form-textarea
                      v-model="userData.about"
                      id="account-about"
                      placeholder="Describe about you to your team mate."
                      rows="3"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <h4 class="mt-1">Contact Information</h4>
              <b-row>
                <b-col sm="6">
                  <b-form-group>
                    <label for="account-email">Email <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="email address"
                      rules="required|email"
                    >
                      <b-form-input
                        v-model="userData.email"
                        :state="errors.length > 0 ? false:null"
                        id="account-email"
                        placeholder="Email"
                        name="email"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group>
                    <label for="account-phone">Mobile phone <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="mobile phone"
                      rules="required|regex:^([0-9]+)$"
                    >
                      <b-form-input
                        v-model="userData.tel"
                        :state="errors.length > 0 ? false:null"
                        id="account-phone"
                        placeholder="Mobile phone"
                        name="phone"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group>
                    <label for="account-company-email">Company email</label>
                    <b-form-input
                      v-model="userData.companyEmail"
                      id="account-company-email"
                      placeholder="e.g. admin@akarahospitality.com"
                      name="company-email"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group>
                    <label for="account-office-phone">Office desk telephone</label>
                    <b-form-input
                      v-model="userData.officePhone"
                      id="account-office-phone"
                      placeholder="Office desk telephone"
                      name="office-phone"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>

    <div class="fixed-footer">
      <div>
        <b-button
          variant="danger"
          @click="confirmDelete"
        >
          <feather-icon
            icon="Trash2Icon"
            size="16"
            class="mr-50"
          />
          <span>Delete</span>
        </b-button>
      </div>
      <div>
        <b-button
          variant="outline-secondary"
          :to="{ name: 'user-list'}"
        >
          <span>Cancel</span>
        </b-button>
        <b-button
          class="ml-1"
          variant="success"
          @click="confirmUpdate"
        >
          <span>Update</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BAvatar, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BFormTextarea, BFormFile, BImg
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, regex } from '@validations'
import useUserEdit from './useUserEdit'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BAvatar,
    BFormFile,
    BImg,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormTextarea,

    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      regex,
      avatar: null,
    }
  },
  methods: {
    async handleUploadAvatar(file) {
      if (!file) return;

      const form = new FormData();
      form.append("files", file);

      const { data, status } = await this.uploadImage(form);
      if (status !== 200) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Error upload image",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return;
      }

      this.userData.avatarUrl = data[0];
      this.$forceUpdate();
    },
    confirmUpdate() {
      this.$refs.selectForm.validate().then(success => {
        if (!success) return
        this.$refs.inputForm.validate().then(success => {
          if (!success) return
          this.$swal({
            title: 'Are you sure to update other user information?',
            text: 'Information will be save and allow team to see profile.',
            icon: 'warning',
            iconColor: '#FF9F43',
            showCancelButton: true,
            confirmButtonText: 'Update',
            customClass: {
              htmlContainer: 'my-50',
              actions: 'flex-row-reverse my-1',
              confirmButton: 'btn btn-success px-5',
              cancelButton: 'btn btn-outline-secondary px-5 mr-1',
            },
            buttonsStyling: false,
          }).then(async (result) => {
            if (!result.value) return

            const { status } = await this.updateUser(this.userData)
            if (status !== 200) {
              this.makeToast(
                'Update user failed!',
                'Can not update user!',
                false,
              )
              return;
            }

            this.$router
              .push({ name: 'user-list' })
              .then(() => {
                this.makeToast(
                  'Update Succeeded!',
                  `User has been updated “${this.userData.firstname} ${this.userData.lastname}”.`,
                )
              })
          })
        })
      })
    },
    confirmDelete() {
      this.$swal({
        title: 'Are you sure to delete account?',
        text: 'This will permanently erase your account. You can’t undo action.',
        icon: 'warning',
        iconColor: '#FF9F43',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          htmlContainer: 'my-50',
          actions: 'flex-row-reverse my-1',
          confirmButton: 'btn btn-danger px-5',
          cancelButton: 'btn btn-outline-secondary px-5 mr-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (!result.value) return

        const { status } = await this.deleteUser(this.userData.id)
        if (status !== 200) {
          this.makeToast(
            'Delate user failed!',
            'Can not delete user!',
            false,
          )
          return;
        }

        this.$router
          .push({ name: 'user-list' })
          .then(() => {
            this.makeToast(
              'Delete Succeeded!',
              `User has been deleted “${this.userData.fullName}”.`,
            )
          })
      })
    },
    async makeResetPasswordToast(id, email) {
      const { status } = await this.resetPassword({ id, email });
      if (status !== 200) {
        this.makeToast(
          'Send email failed!', 
          `Can not send email to user.`,
        );
        return;
      }

      this.makeToast(
        'Reset password succeeded!',
        'Please check the email.',
      )
    },
    makeToast(title, message, success = true) {
      this.$bvToast.toast(
        (
          <div class="d-flex">
            <feather-icon
              class={success ? 'text-success' : 'text-danger'}
              icon={success ? 'CheckCircleIcon' : 'XCircleIcon'}
              size="24"
            />
            <div class="d-flex flex-column">
              <h4 class={success ? 'ml-1 text-success' : 'ml-1 text-danger'}>{title}</h4>
              <div class="ml-1 font-14px">{message}</div>
            </div>
          </div>
        ),
        {
          headerClass: 'd-none',
          solid: true,
        },
      )
    },
  },
  setup() {
    const {
      toast,
      uploadImage,
      updateUser,
      resetPassword,
      deleteUser,
      roleOptions,
      statusOptions,
      resolveUserRoleVariant,
    } = useUserEdit()

    return {
      toast,
      uploadImage,
      updateUser,
      resetPassword,
      deleteUser,
      roleOptions,
      statusOptions,
      avatarText,
      resolveUserRoleVariant,
    }
  },
}
</script>