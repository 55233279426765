import store from '@/store'
import axios from '@axios'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUserEdit() {
  const toast = useToast()
  const roleOptions = [
    { label: 'Administrator', value: 'admin' },
    { label: 'Author', value: 'author' },
    { label: 'Editor', value: 'editor' },
    { label: 'Maintainer', value: 'maintainer' },
    { label: 'Subscriber', value: 'subscriber' },
  ]
  const statusOptions = [
    { label: 'Activate', value: 'Activate' },
    { label: 'Inactivate', value: 'Inactivate' },
  ]

  const fetchUser = async (id) => {
    try {
      const response = await store.dispatch('app-user/fetchUser', id);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetch card',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const updateUser = async (card) => {
    try {
      const response = await store.dispatch('app-user/updateUser', card);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error update card',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const setPassword = async ({ id, email }) => {
    try {
      const response = await store.dispatch('app-user/setPassword', { id, email });
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error send email to user.',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const resetPassword = async ({ id, email }) => {
    try {
      const response = await store.dispatch('app-user/resetPassword', { id, email });
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error send email to user.',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const deleteUser = async (id) => {
    try {
      const response = await store.dispatch('app-user/deleteUser', id);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error delete card',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const uploadImage = async (images) => {
    try {
      const response = await axios.post('/uploader', images, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error upload image',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  return {
    toast,
    uploadImage,
    fetchUser,
    updateUser,
    setPassword,
    resetPassword,
    deleteUser,
    roleOptions,
    statusOptions,
    resolveUserRoleVariant,
  }
}
