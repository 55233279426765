<template>
  <div v-if="userData">
    <user-edit-active
      v-if="userData.status === 'active' || userData.status === 'Activate'"
      :user-data="userData"
    />
    <user-edit-inactive
      v-else
      :user-data="userData"
    />
  </div>
</template>

<script>
import {
  BAvatar, BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BFormTextarea, BLink, BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import UserEditActive from './UserEditActive.vue'
import UserEditInactive from './UserEditInactive.vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import useUserEdit from './useUserEdit'
import userStoreModule from '../userStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BAvatar,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormTextarea,

    vSelect,
    UserEditActive,
    UserEditInactive,
  },
  data() {
    return {
      userData: {}
    }
  },
  async created() {
    const app = document.getElementById('app')
    const appLoading = document.getElementById('loading-bg')
    const appLoadingImage = document.querySelector('#loading-bg > img')
    if (appLoading) {
      app.style.overflow = 'hidden'
      appLoading.style.zIndex = '9'
      appLoading.style.display = 'block'
      appLoadingImage.src = `/loading.gif?${Math.random()}`
    }
    try {
      const id = this.$router.currentRoute.params.id;
      const { data } = await this.fetchUser(id);
      const { password, ...dataWithoutPassword } = data
      Object.entries(dataWithoutPassword).forEach(([key, _]) => {
        if (dataWithoutPassword[key] === 'null')
          dataWithoutPassword[key] = ''
      });
      this.userData = dataWithoutPassword;
      if (appLoading) {
        app.style.overflow = 'auto'
        appLoading.style.display = 'none'
      }
    } catch (error) {
      this.toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching user',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      toast,
      fetchUser,
      roleOptions,
      statusOptions,
    } = useUserEdit()

    return {
      toast,
      fetchUser,
      roleOptions,
      statusOptions,
    }
  },
}
</script>